<script setup lang="ts" generic="T">
const props = withDefaults(defineProps<{
    value: T | undefined | null;
    size?: string;
}>(), {
    size: '5rem',
});

const isLoaded = computed(() => {
    return props.value !== undefined && props.value !== null;
});
</script>

<template>
    <section class="w-100">
        <slot name="header"></slot>
        <div v-if="isLoaded">
            <slot></slot>
        </div>
        <p v-if="!isLoaded" class="text-center">
            <Spinner :size="size" />
        </p>
    </section>
</template>
